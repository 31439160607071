








import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TextContent extends Vue {
  @Prop(Array) readonly items!: object;
  @Prop(String) readonly label!: string;
}
